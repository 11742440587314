import { Breed, PackageType } from './priceListData.types'

export const breeds: Breed[] = [
  {
    value: 'shihTzu',
    label: 'Shih tzu',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 100
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 130
      },
      {
        type: PackageType.PRO,
        duration: 2.5,
        price: 160
      }
    ]
  },
  {
    value: 'lhasaApso',
    label: 'Lhasa Apso',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 100
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 130
      },
      {
        type: PackageType.PRO,
        duration: 2.5,
        price: 160
      }
    ]
  },
  {
    value: 'york',
    label: 'York',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 100
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 120
      },
      {
        type: PackageType.PRO,
        duration: 2.5,
        price: 150
      }
    ]
  },
  {
    value: 'maltanczyk',
    label: 'Maltańczyk',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 100
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 130
      },
      {
        type: PackageType.PRO,
        duration: 2.5,
        price: 160
      }
    ]
  },
  {
    value: 'pomeranian',
    label: 'Pomeranian',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 110
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 140
      },
      {
        type: PackageType.PRO,
        duration: 2.5,
        price: 180
      }
    ]
  },
  {
    value: 'west',
    label: 'West',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 100
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 140
      },
      {
        type: PackageType.TRIMM,
        duration: 3,
        price: 230
      }
    ]
  },
  {
    value: 'spanielAngielskiAmerykanski',
    label: 'Spaniel Angielski/Amerykański',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 140
      },
      {
        type: PackageType.HIGH,
        duration: 2.5,
        price: 180
      },
      {
        type: PackageType.TRIMM,
        duration: 3,
        price: 240
      },
      {
        type: PackageType.PRO_TRIMM,
        duration: 3.5,
        price: 280
      }
    ]
  },
  {
    value: 'cavalierKingCharlesSpaniel',
    label: 'Cavalier King Charles Spaniel',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 110
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 150
      },
      {
        type: PackageType.TRIMM,
        duration: 2.5,
        price: 190
      },
      {
        type: PackageType.PRO_TRIMM,
        duration: 3,
        price: 240
      }
    ]
  },
  {
    value: 'sznaucerMiniaturowy',
    label: 'Sznaucer Miniaturowy',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 100
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 130
      },
      {
        type: PackageType.TRIMM,
        duration: 2.5,
        price: 190
      },
      {
        type: PackageType.PRO_TRIMM,
        duration: 3,
        price: 240
      }
    ]
  },
  {
    value: 'sznaucerSredni',
    label: 'Sznaucer Średni',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 110
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 190
      },
      {
        type: PackageType.TRIMM,
        duration: 2.5,
        price: 220
      },
      {
        type: PackageType.PRO_TRIMM,
        duration: 3,
        price: 260
      }
    ]
  },
  {
    value: 'sznaucerOlbrzymi',
    label: 'Sznaucer Olbrzymi',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 2,
        price: 250
      },
      {
        type: PackageType.HIGH,
        duration: 3,
        price: 320
      },
      {
        type: PackageType.TRIMM,
        duration: 3.5,
        price: 380
      },
      {
        type: PackageType.PRO_TRIMM,
        duration: 4,
        price: 420
      }
    ]
  },
  {
    value: 'labradorRetriever',
    label: 'Labrador Retriever',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 150
      }
    ]
  },
  {
    value: 'goldenRetriever',
    label: 'Golden Retriever',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 160
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 200
      },
      {
        type: PackageType.PRO,
        duration: 2.5,
        price: 230
      }
    ]
  },
  {
    value: 'owczarekNiemiecki',
    label: 'Owczarek Niemiecki',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 2,
        price: 200
      },
      {
        type: PackageType.HIGH,
        duration: 2.5,
        price: 240
      }
    ]
  },
  {
    value: 'samojed',
    label: 'Samojed',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 2.5,
        price: 300
      }
    ]
  },
  {
    value: 'buldogFrancuski',
    label: 'Buldog Francuski',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 100
      }
    ]
  },
  {
    value: 'mops',
    label: 'Mops',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 100
      }
    ]
  },
  {
    value: 'pudelToy',
    label: 'Pudel Toy',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 110
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 150
      },
      {
        type: PackageType.PRO,
        duration: 2.5,
        price: 180
      }
    ]
  },
  {
    value: 'pudelMiniaturowy',
    label: 'Pudel Miniaturowy',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 120
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 170
      },
      {
        type: PackageType.PRO,
        duration: 2.5,
        price: 200
      }
    ]
  },
  {
    value: 'pudelSredni',
    label: 'Pudel Średni',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 150
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 220
      },
      {
        type: PackageType.PRO,
        duration: 2.5,
        price: 260
      }
    ]
  },
  {
    value: 'pudelKrolewski',
    label: 'Pudel Królewski',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 3,
        price: 280
      },
      {
        type: PackageType.HIGH,
        duration: 4.5,
        price: 330
      },
      {
        type: PackageType.PRO,
        duration: 5,
        price: 380
      }
    ]
  },
  {
    value: 'alaskanMalamute',
    label: 'Alaskan Malamute',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 2.5,
        price: 280
      }
    ]
  },
  {
    value: 'akita',
    label: 'Akita',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 2,
        price: 200
      }
    ]
  },
  {
    value: 'jackRusselTerrier',
    label: 'Jack Russel Terrier',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 90
      },
      {
        type: PackageType.TRIMM,
        duration: 2,
        price: 170
      }
    ]
  },
  {
    value: 'bernardyn',
    label: 'Bernardyn',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 3,
        price: 400
      }
    ]
  },
  {
    value: 'borderCollie',
    label: 'Border Collie',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 140
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 170
      }
    ]
  },
  {
    value: 'buldogAngielski',
    label: 'Buldog Angielski',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 120
      }
    ]
  },
  {
    value: 'chowChow',
    label: 'Chow Chow',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 2.5,
        price: 300
      },
      {
        type: PackageType.PRO,
        duration: 3,
        price: 350
      }
    ]
  },
  {
    value: 'labradoodle',
    label: 'Labradoodle',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 150
      },
      {
        type: PackageType.HIGH,
        duration: 3,
        price: 220
      },
      {
        type: PackageType.PRO,
        duration: 3.5,
        price: 350
      }
    ]
  },
  {
    value: 'amstaff',
    label: 'Amstaff',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 130
      }
    ]
  },
  {
    value: 'bulterier',
    label: 'Bulterier',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 130
      }
    ]
  },
  {
    value: 'beagle',
    label: 'Beagle',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 130
      }
    ]
  },
  {
    value: 'hawanczyk',
    label: 'Hawańczyk',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 110
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 150
      },
      {
        type: PackageType.PRO,
        duration: 2.5,
        price: 180
      }
    ]
  },
  {
    value: 'bolonczyk',
    label: 'Bolończyk',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 110
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 150
      },
      {
        type: PackageType.PRO,
        duration: 2.5,
        price: 180
      }
    ]
  },
  {
    value: 'wielorasoweKrotkowłoseMaleDo10kg',
    label: 'Wielorasowe krótkowłose małe do 10kg',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 100
      }
    ]
  },
  {
    value: 'wielorasoweKrotkowłoseSrednieDo20kg',
    label: 'Wielorasowe krótkowłose średnie do 20kg',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 120
      }
    ]
  },
  {
    value: 'wielorasoweKrotkowłoseDuzeDo40kg',
    label: 'Wielorasowe krótkowłose duże do 40kg',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 160
      }
    ]
  },
  {
    value: 'wielorasoweDlugowloseMaleDo10kg',
    label: 'Wielorasowe długowłose małe do 10kg',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1,
        price: 120
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 150
      },
      {
        type: PackageType.PRO,
        duration: 2.5,
        price: 200
      }
    ]
  },
  {
    value: 'wielorasoweDlugowloseSrednieDo20kg',
    label: 'Wielorasowe długowłose średnie do 20kg',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 160
      },
      {
        type: PackageType.HIGH,
        duration: 2,
        price: 190
      },
      {
        type: PackageType.PRO,
        duration: 2.5,
        price: 220
      }
    ]
  },
  {
    value: 'wielorasoweDlugowloseDuzeDo40kg',
    label: 'Wielorasowe długowłose duże do 40kg',
    availablePackages: [
      {
        type: PackageType.BASIC,
        duration: 1.5,
        price: 220
      },
      {
        type: PackageType.HIGH,
        duration: 2.5,
        price: 270
      },
      {
        type: PackageType.PRO,
        duration: 3,
        price: 300
      }
    ]
  }
]

export const additionalServices = [
  {
    type: 'Usuwanie kamienia nazębnego od',
    price: 200
  },
  {
    type: 'Usuwanie kleszczy od',
    price: 10
  },
  {
    type: 'Wyczesywanie kołtunów podczas wizyty, za każdą dodatkową godzinę pracy +',
    price: 60
  }
]
