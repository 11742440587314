import React from 'react'
import classNames from 'classnames'
import { Breed, Packages } from '../../priceListData/priceListData.types'
import { additionalServices } from '../../priceListData/priceListData'
import * as styles from './PriceList.module.scss'

type PriceListProps = {
  breed: Breed
}

function PriceList({ breed }: PriceListProps) {
  return (
    <div className="mt-4">
      {breed.availablePackages.map(pack => (
        <div key={pack.type} className={styles.pack}>
          <div>
            Pakiet{' '}
            <span className={styles.strong}>{Packages[pack.type].name}</span>
          </div>
          <div>{Packages[pack.type].description}</div>
          <div>
            Czas trwania:{' '}
            <span className={styles.strong}>{pack.duration}h</span>
          </div>
          <div>
            Cena: <span className={styles.strong}>{pack.price} zł</span>
          </div>
        </div>
      ))}
      <div className={classNames('mt-4')}>Usługi dodatkowe:</div>
      {additionalServices.map(service => (
        <div key={service.type} className={classNames('mt-4', styles.pack)}>
          <div>
            {service.type} {service.price} zł
          </div>
        </div>
      ))}
    </div>
  )
}

export default PriceList
