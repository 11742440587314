export interface Package {
  name: string
  description: string
}

export enum PackageType {
  BASIC = 'basic',
  HIGH = 'high',
  PRO = 'pro',
  TRIMM = 'trimm',
  PRO_TRIMM = 'proTrimm'
}

export const Packages: Record<PackageType, Package> = {
  basic: {
    name: 'Basic',
    description: 'Kąpiel z suszeniem i wyczesywaniem'
  },
  high: {
    name: 'High',
    description: 'Kąpiel, suszenie, wyczesywanie, strzyżenie'
  },
  pro: {
    name: 'Pro',
    description: 'Kąpiel, odżywianie włosa, suszenie, wyczesywanie, strzyżenie'
  },
  trimm: {
    name: 'Trimm',
    description: 'Kąpiel, suszenie, wyczesywanie, trymowanie, strzyżenie'
  },
  proTrimm: {
    name: 'Pro + Trymowanie',
    description:
      'Kąpiel, odżywianie włosa, suszenie, wyczesywanie, trymowanie, strzyżenie'
  }
}

export interface BreedPackage {
  type: PackageType
  price: number
  duration: number
}

export interface Breed {
  value: string
  label: string
  availablePackages: BreedPackage[]
}
