import * as React from 'react'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import Select, {
  ActionMeta,
  GroupBase,
  MultiValue,
  StylesConfig
} from 'react-select'
import * as styles from '../styles/cennik.module.scss'

import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO'
import PageContent from '../components/PageContent/PageContent'
import { breeds } from '../priceListData/priceListData'
import { Breed } from '../priceListData/priceListData.types'
import PriceList from '../components/PriceList/PriceList'

const colorGold = '#e0ad39'
const colorGoldActive = '#f5cc89'

const selectStyles: StylesConfig<never> = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused
      ? `1px solid ${colorGoldActive}`
      : `1px solid ${colorGold}`,
    background: 'transparent',
    ':hover': {
      ...provided[':hover'],
      border: `1px solid ${colorGoldActive}`
    },
    ':active': {
      ...provided[':active'],
      border: `1px solid ${colorGoldActive}`
    },
    boxShadow: 'none',
    color: colorGold
  }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: colorGold
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: colorGold,
    ':hover': {
      ...provided[':hover'],
      color: colorGoldActive,
      cursor: 'pointer'
    }
  }),
  menu: provided => ({
    ...provided,
    border: `1px solid ${colorGold}`,
    boxShadow: 'none'
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
    color: colorGold,
    background: 'black'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected || state.isFocused ? colorGoldActive : colorGold,
    backgroundColor: state.isSelected ? 'rgba(0,0,0,0.9)' : 'black',
    ':hover': {
      ...provided[':hover'],
      color: colorGoldActive,
      backgroundColor: 'rgba(0,0,0,0.9)',
      cursor: 'pointer'
    }
  }),
  singleValue: provided => ({
    ...provided,
    color: colorGold
  }),
  input: provided => ({
    ...provided,
    color: colorGold,
    // marginBottom: '-2px',
    caretPosition: 'top'
  }),
  placeholder: provided => ({
    ...provided,
    color: 'rgba(224,173,57,0.6)',
    marginBottom: '-2px'
  })
}

function CennikPage() {
  const [selectedBreedValue, setSelectedBreedValue] = useState<string>()
  const [selectedBreed, setSelectedBreed] = useState<Breed>()

  const findBreed = (breedValue: string) => {
    const breed = breeds.find(b => b.value === breedValue)
    setSelectedBreed(breed)
  }

  useEffect(() => {
    if (selectedBreedValue) {
      findBreed(selectedBreedValue)
    }
  }, [selectedBreedValue])

  const handleSelection = (option: Breed | null) => {
    setSelectedBreedValue(option?.value)
  }

  return (
    <Layout>
      <SEO title="Cennik" description="Cennik usług salonu" />
      <div
        className={classNames(
          'h-screen relative max-w-[100vw] overflow-x-hidden',
          styles.container,
          styles.container__background
        )}
      >
        <PageContent title="CENNIK">
          <div className="max-w-full md:max-w-[500px] m-auto">
            <h4 className="mb-2">Wybierz rasę swojego psa:</h4>
            <Select
              options={breeds as unknown as GroupBase<never>[]}
              styles={selectStyles}
              placeholder="Wybierz lub wpisz..."
              noOptionsMessage={() => 'Brak takiej rasy'}
              onChange={
                handleSelection as (
                  newValue: MultiValue<never> | null,
                  actionMeta: ActionMeta<never>
                ) => void
              }
            />
            {selectedBreed && <PriceList breed={selectedBreed} />}
          </div>
        </PageContent>
      </div>
    </Layout>
  )
}

export default CennikPage
